import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PreventInspectService {

  constructor(private router: Router, private _auth: AuthService, private deviceService: DeviceDetectorService, private modalService: BsModalService, private _toast: ToastService, private translate: TranslateService) { }

  init() {

    let element = new Image();
    let checked = false
    Object.defineProperty(element, 'id', {
      get: () => {
        if (!checked) {
          this._auth.setUserUnAuthenticated()
          // this.restrictUser()
          this._toast.showToast(this.translate.instant(`you have been reported, and your account has been disabled for violating our terms`), 'error')
          window.location.reload()
          checked = true
          throw new Error("Dev tools checker");
        }

      }
    });

    requestAnimationFrame(function check() {
      // alert('closed')
      console.dir(element);
      console.clear()
      requestAnimationFrame(check);
    });

  }

  restricted: boolean = false
  restrictUser() {
    if (this.restricted) {
      return
    }
    this.restricted = true
    this._auth.restrictUser().subscribe(res => {
      console.log(res);

    }, err => {
      console.log(err);

    })
  }
}
