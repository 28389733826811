
<div class="bg"></div>
<div class="success">
  <div>

  <span class="check"></span>
    <h1>{{"Your Order is Confirmed!" | translate}}</h1>
    <h4>{{"Your Order Number"|translate}}</h4>
    <h4>{{"#ABE-ME-12345678 "|translate}}</h4>
  </div>
</div>

