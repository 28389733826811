import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-after-signup',
  templateUrl: './after-signup.component.html',
  styleUrls: ['./after-signup.component.scss']
})
export class AfterSignupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
