<!-- Signup Start -->
<div class="container">
    <div class="row justify-content-lg-center justify-content-md-center">
        <div class="col-lg-12">
            <div class="main_logo25" id="logo">
                <a ><img src="assets/images/main-logo.png" alt=""></a>
                <a ><img class="logo-inverse" src="assets/images/logo.png" alt=""></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-8">
            <div class="sign_form">
                <h2>{{'Reset Your Password' | translate}}</h2>
                <div class="alert alert-danger" role="alert" *ngIf="resetForm.dirty && (resetForm.invalid || resetForm.get('confirmPassword').invalid || resetForm.get('confirmPassword').value !== resetForm.get('password').value)">
                    <span *ngIf="resetForm.get('password').invalid ">
                        {{"please enter a valid password, min 8 characters" | translate}}</span><br>
                    <span
                        *ngIf="resetForm.get('confirmPassword').invalid || resetForm.get('confirmPassword').value !== resetForm.get('password').value">
                        {{"password doesn't match" | translate}}</span><br>
                </div>

                <form *ngIf="confirmReset" [formGroup]="resetForm">
                    <div class="ui search focus mt-50">
                        <div class="ui left icon input swdh95">
                            <input formControlName="password" class="prompt srch_explore" type="password"
                                name="New Password" value="" id="id_password" required=""
                                placeholder="Enter New Password">
                            <i class="uil uil-key-skeleton-alt icon icon2"></i>
                        </div>
                    </div>
                    <div class="ui search focus mt-4">
                        <div class="ui left icon input swdh95">
                            <input formControlName="confirmPassword" class="prompt srch_explore" type="password"
                                name="Confirm Password" value="" id="id_password_confirm" required=""
                                placeholder="Confirm New Password">
                            <i class="uil uil-key-skeleton-alt icon icon2"></i>
                        </div>
                    </div>
                    <button (click)="onResetClick()"
                        [ngStyle]="{'background-color':(resetForm.invalid || resetForm.get('confirmPassword').invalid || resetForm.get('confirmPassword').value !== resetForm.get('password').value)? 'darkgray' : null}"
                        [disabled]="(resetForm.invalid || resetForm.get('confirmPassword').invalid || resetForm.get('confirmPassword').value !== resetForm.get('password').value)" class="login-btn">{{'Reset My Password' | translate}}</button>
                </form>
                <p *ngIf="!confirmReset">{{"Invalid reset password link" | translate}}</p>
                <button *ngIf="!confirmReset" [routerLink]="['']" routerLinkActive="router-link-active"
                    class="login-btn">{{'Back to home' | translate}}</button>
            </div>
            <div class="sign_footer"><img src="assets/images/sign_logo.png" alt="">© 2020 <strong>Smart
                    Academy</strong>. All Rights Reserved.</div>
        </div>
    </div>
</div>
<!-- Signup End -->