
<div class="bg"></div>
<div class="success">
  <div>

  <span class="icon-close"></span>
  <h1>{{"Process Faild  " | translate}}</h1>
  <h4>{{"Please Try Again"|translate}}</h4>
</div>
</div>


