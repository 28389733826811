<!-- Header Start -->
<header class="lp-header">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <!-- Navbar brand--><a  [routerLink]="['']" routerLinkActive="router-link-active" class="navbar-brand font-weight-bold"><img src="assets/images/main-logo.png" alt="..." class="img-fluid"></a>
        <!-- Navbar toggler button-->
        <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler navbar-toggler-right">Menu<i class="icon ion-md-list ml-2"></i></button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav mx-auto ml-auto">
                <!-- Link-->
                <!-- <li class="nav-item"> <a href="schedule.html" class="nav-link">About Us</a></li> -->

            <!-- <li class="nav-item dropdown"><a id="pages" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">departments</a>
              <div class="dropdown-menu">
                <a href="#" class="dropdown-item">Arabic</a>
                <a href="#" class="dropdown-item">English</a>
                <a href="#" class="dropdown-item">Math</a></div>
            </li> -->
            <!-- Link-->
            <!-- <li class="nav-item"> <a [routerLink]="['']" routerLinkActive="router-link-active"  class="nav-link">{{"Home" | translate}}</a></li> -->
          </ul>
          <ul class="navbar-nav" *ngIf="!_auth.isLoggedIn">
            <li [routerLink]="['/login']" routerLinkActive="router-link-active"  class="nav-item"><a  data-toggle="modal" data-target="#login" class="nav-link font-weight-bold mr-3">{{"Login" | translate}}</a></li>
            <li [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="nav-item"><a  class="signupbutton navbar-btn btn btn-primary">{{"Get Started" | translate}}</a></li>
          </ul>
          <ul class="navbar-nav" *ngIf="_auth.isLoggedIn">
            <li (click)="goToDashboard()" class="nav-item"><a
                class="navbar-btn btn Dassh btn-primary">{{"My Dashboard" | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>


<!-- Header End -->
<!-- Body Start -->
<div class="wrapper p-0 _new89">

<div class="contact1256 contectUs mt-0">
    <div class="_215b15">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="title125">
                        <div class="titleleft">
                            <div class="ttl121">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li [routerLink]="['']" routerLinkActive="router-link-active"  class="breadcrumb-item"><a >{{'Home' | translate}}</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">{{'Contact Us' | translate}}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="title126">
                        <h2>{{'Contact Us' | translate}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact_map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3435.358054333779!2d31.009402315127502!3d30.567436181695086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDM0JzAyLjgiTiAzMcKwMDAnNDEuNyJF!5e0!3m2!1sen!2seg!4v1596052716019!5m2!1sen!2seg" width="748" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            <div class="col-lg-4">
                <div class="contact_info">
                    <div class="checkout_title">
                        <h4>{{'Contact Information' | translate}}</h4>
                        <img src="images/line.svg" alt="">
                    </div>
                    <ul class="contact_list_info">
                        <li><div class="txt_cntct"><span class="cntct_895"><i class="uil uil-location-point"></i>{{"Main Address :" | translate}}</span><p>شارع جمال عبد الناصر, بجوار نادي الجمهورية, شبين الكوم, المنوفية</p></div></li>
                        <li><div class="txt_cntct"><span class="cntct_895"><i class="uil uil-envelope"></i>{{"Email Address :" | translate}}</span><p> <a href="mailto:ask@smartacademy.center">ask@smartacademy.center</a> </p></div></li>
                        <li><div class="txt_cntct"><span class="cntct_895"><i class="uil uil-mobile-android-alt"></i>{{"Phone Number :" | translate}}</span><p> <a href="tel:01093013498">01093013498</a> , <a href="tel:01093418694">01093418694</a> </p></div></li>
                    </ul>
                    <div class="edututs_links_social">

                        <!-- <ul class="tutor_social_links">
                            <li><a href="https://www.facebook.com/smartacademymnf/" class="fb"><i class="uil uil-facebook-f"></i></a></li>
                        </ul> -->


                        <div class="col-md-6 socialMedia">
                          <div class="edu_social_links">
                              <a href="https://www.facebook.com/smartacademymnf/"><i class="uil uil-facebook-f"></i></a>
                              <a><i class="uil uil-twitter"></i></a>
                              <a><i class="uil uil-linkedin"></i></a>
                              <a><i class="uil uil-instagram"></i></a>
                              <a><i class="uil uil-youtube"></i></a>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer mt-40">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="item_f1">
                    <a >Copyright Policy</a>
                    <a >Terms</a>
                    <a >Privacy Policy</a>
                </div>
                <!-- <div class="col-md-6 socialMedia">
                  <div class="edu_social_links">
                      <a><i class="uil uil-facebook-f"></i></a>
                      <a><i class="uil uil-twitter"></i></a>
                      <a><i class="uil uil-linkedin"></i></a>
                      <a><i class="uil uil-instagram"></i></a>
                      <a><i class="uil uil-youtube"></i></a>
                  </div>
              </div> -->
            </div>
            <div class="col-lg-12">
                <div class="footer_bottm">
                    <div class="row">
                        <div class="col-md-6">
                            <ul class="fotb_left">

                                <li>
                                    <p>© 2020 <strong>Smart Academy</strong>. All Rights Reserved.</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
</div>
<!-- Body End -->
