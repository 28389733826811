<!-- Signup Start -->
<div class="sign_in_up_bg">
    <div class="container">
        <div class="row justify-content-lg-center justify-content-md-center">
            <div class="col-lg-12">
                <div class="main_logo25" id="logo">
                    <a [routerLink]="['']" routerLinkActive="router-link-active"><img src="assets/images/main-logo.png"
                            alt=""></a>
                    <a [routerLink]="['']" routerLinkActive="router-link-active"><img class="logo-inverse"
                            src="assets/images/logo.png" alt=""></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-8">
                <div class="sign_form">
                    <h2>{{'Request a Password Reset' | translate}}</h2>
                    <div class="alert alert-danger" role="alert"
                        *ngIf="resetForm.get('email').invalid && resetForm.dirty">
                        <span> {{"please enter a valid mail" | translate}}</span><br>
                    </div>
                    <form [formGroup]="resetForm">
                        <span *ngIf="!sent">
                            <div class="ui search focus mt-50">
                                <div class="ui left icon input swdh95">
                                    <input formControlName="email" class="prompt srch_explore"
                                        placeholder="{{'Email Address' | translate}}">
                                    <i class="uil uil-envelope icon icon2"></i>
                                </div>
                            </div>
                            <button (click)="onResetClick()"
                                [ngStyle]="{'background-color':resetForm.invalid? 'darkgray' : null}"
                                [disabled]="resetForm.invalid"
                                class="login-btn">{{'Reset Password' | translate}}</button>
                        </span>
                        <span *ngIf="sent">
                            <div class="ui search focus mt-50">
                                <p>{{"Reset link Sent, Please Check Your mail" | translate}}</p>
                            </div>
                            <button [routerLink]="['']" routerLinkActive="router-link-active"
                                class="login-btn">{{'Back to home' | translate}}</button>
                        </span>
                    </form>
                    <p class="mb-0 mt-30">Go Back <a [routerLink]="['/login']"
                            routerLinkActive="router-link-active">{{'Sign In' | translate}}</a></p>
                </div>
                <div class="sign_footer"><img src="assets/images/sign_logo.png" alt="">© 2020 <strong></strong>.
                    All Rights Reserved.</div>
            </div>
        </div>
    </div>
</div>
<!-- Signup End -->