import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idmpage',
  templateUrl: './idmpage.component.html',
  styleUrls: ['./idmpage.component.scss']
})
export class IDMPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
