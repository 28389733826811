
<div class="bg"></div>
<div class="idmPage">
  <div>
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-exclamation-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
    </svg>
    <h1>{{"Please Don't use IDM Extension to be able to use this website" | translate}}</h1>
    <img src="../../../assets/images/logo.png" class="logo" alt="">
    <h4>{{"Sorry your account is blocked if you want to unblock it "|translate}}<a href="">{{"Contact Us"|translate}}</a></h4>
  </div>
</div>
