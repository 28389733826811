<!-- Body Start -->
<div class="coming_soon_style">
    <div class="wrapper coming_soon_wrapper ">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div  class="cmtk_group">
                        <div class="ct-logo">
                            <a [routerLink]="['']" routerLinkActive="router-link-active" ><img src="images/ct_logo.svg" alt=""></a>
                        </div>
                        <div class="cmtk_dt">
                            <h1 class="thnk_coming_title">{{'Thank You' | translate}}</h1>
                            <h4 class="thnk_title1">{{'You account has been created successfully, please check your mail to active your account.' | translate}} </h4>
                            <button [routerLink]="['']" routerLinkActive="router-link-active"  class="plan_link_btn">{{'Back to Home' | translate}}</button>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Body End -->