import { PreventInspectService } from './../preventInspect/prevent-inspect.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './../toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from '@angular/core';
import html2canvas from "html2canvas";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BsModalService } from 'ngx-bootstrap/modal';
@Injectable({
  providedIn: 'root'
})
export class ProtectVideosService {

  constructor(private router: Router, private deviceService: DeviceDetectorService, private modalService: BsModalService, private _toast: ToastService, private translate: TranslateService,
    private _preventInspect: PreventInspectService, private _auth: AuthService) { }

  private setCheckInterval(preventInspect = false) {
    let isListenerAdded = false
    if (!isListenerAdded) {
      let isReportSent = false
      let DEMM6ucWUN = setInterval(() => {
        if (document.querySelectorAll('[__idm_id__]').length > 0 || document.querySelectorAll('[__idm_frm__]').length > 0) {
          // console.log(document.querySelectorAll('[__idm_id__]'), document.querySelectorAll('[__idm_frm__]'));
          if (!isReportSent) {
            isReportSent = true
            this._toast.showToast(this.translate.instant(`you have been reported, and your account has been disabled for violating our terms`), 'error')
            // this.takeScreenshot();
            //send report to the API
            this.restrictUser();
            localStorage.clear();
            this.router.navigate(['idm'])
            return
          }
          localStorage.clear();
          this.router.navigate(['idm'])

        }

      }, 2000);
      //
      this.initShortcuts(DEMM6ucWUN)
    }
    preventInspect ? this._preventInspect.init() : null;
  }

  check() {
    if (document.querySelectorAll('[__idm_id__]').length > 0 || document.querySelectorAll('[__idm_frm__]').length > 0) {
      //send report to the API
      // this.restrictUser()
      this.setCheckInterval()
      return true
    }
    this.setCheckInterval()
  }
  private initShortcuts(DEMM6ucWUN) {
    if (!environment.production) {

      document.onkeyup = (e) => {
        //ctrl alt t
        if (e.ctrlKey && e.altKey && e.which == 84) {
          // console.log('enable idm protect');
          alert('enable idm protect')
          DEMM6ucWUN ? null : this.setCheckInterval()
        }
        //ctrl alt y
        if (e.ctrlKey && e.altKey && e.which == 89) {
          // console.log('disable idm protect');
          alert('disable idm protect')
          clearInterval(DEMM6ucWUN)
          DEMM6ucWUN = null
        }
      }
    }
  }
  private takeScreenshot() {
    html2canvas(document.body).then(canvas => {
      let imgData = canvas.toDataURL("image/png");
      canvas.toBlob(blob => {
        let imgTag = document.createElement('img')
        imgTag.setAttribute('src', imgData)
        // console.log(this.blobToFile(blob, 'fileName'));
        // console.log(this.deviceService.getDeviceInfo(), { isMobile: this.deviceService.isMobile(), isDesktop: this.deviceService.isDesktop() });

        document.body.appendChild(imgTag)

        // this._auth.setUserUnAuthenticated()
        this.router.navigate(['idm'])
      })

    });
  }

  private blobToFile(theBlob: Blob, fileName: string): File {

    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  restrictUser() {
    this._auth.restrictUser().subscribe(res => {
      console.log(res);

    }, err => {
      console.log(err);

    })
  }
}
